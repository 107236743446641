.blog-post-container {
}

.blog-post-container img {
    max-width: 100%;
    max-height: 300px;
    object-fit: scale-down;
    border-radius: 6px;
}

.blog-post-container figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.htoc {
    padding: 12px;
    border: 1px solid #EFEFEF;
    border-radius: 6px;
    margin-bottom: 18px;
    margin-top: 18px;
}

.htoc__title {
    display: block;
    font-size: 16pt;
    font-weight: 500;
    padding-bottom: 12px;
}

.htoc__toggle {
    padding-left: 12px;
}

a {
    color: black;
    font-weight: 500;
    text-decoration: underline;
    text-decoration-color: #32493d33;
    text-decoration-thickness: 2px;
    overflow-wrap: break-word;
}

a:hover{
    color: black;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: #32493dCC;
    text-decoration-thickness: 2px;
    overflow-wrap: break-word;
}


.ht_toc_list > li{
    /* list-style-type: none; */
    font-size: 12pt;
    margin-top: 12px;
}

.ht_toc_child_list > li{
    list-style-type: none;
    font-size: 11pt;
}